/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../sass/global.scss";
import Header from "./header";
import Footer from "./footer";
import { Spotlight } from "./spotlight";
// import AOS from "aos";
import "aos/dist/aos.css";

interface Props {
  children: React.ReactNode;
  slug?: string;
}

const Layout = ({ children, slug }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          socials {
            name
            shortName
            url
          }
        }
      }
    }
  `);

  return (
    <>
      <Spotlight />
      <div id="wrapper">
        <Header
          socials={data.site.siteMetadata.socials}
          siteTitle={data.site.siteMetadata.title}
          slug={slug}
        />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
