import { Link } from "gatsby";
import React from "react";
//
import LogoInline from "../images/svg/deswolf-logo.svg";
//
import github from "../images/social/github.svg";
import stackoverflow from "../images/social/stackoverflow.svg";
import linkedin from "../images/social/linkedin.svg";
import defaultIcon from "../images/social/default.svg";
//
// import closeIcon from "../images/icons/close.svg";

// import { setClasses } from "../helpers/set-classes";

// ? TYPES:
import { Social } from "../models/social.model";

interface Props {
  siteTitle: string;
  socials: Social[];
  slug?: string;
}

const icons: any = {
  get(name: string): string {
    return this[name] || this["default"];
  },
  github: github,
  stackoverflow: stackoverflow,
  linkedin: linkedin,
  default: defaultIcon,
};

// const getSocialIcon = (name: string): string => {
//   return icons[name] || icons["default"];
// };

// TODO: Proper centering css
const SocialIcons = ({ socials }: { socials: Social[] }): JSX.Element => (
  <ul className="socials">
    {socials.map((social: Social) => (
      <li key={social.shortName}>
        <a href={social.url} target="_blank" rel="noopener noreferrer">
          <img src={icons.get(social.shortName)} alt={social.name} />
        </a>
      </li>
    ))}
  </ul>
);

const Header = ({ siteTitle = "", socials = [], slug }: Props): JSX.Element => {
  const isContact = slug === "contact-me";
  const isHome = slug === "home";
  const goBack = (event: any) => {
    if (!isHome) {
      // TODO: Check if prev is within site
      history.back();
      event.preventDefault();
    }
  };
  return (
    <header id="header">
      <div>
        <h1 style={{ width: "45px" }}>
          <Link
            className="svg-holder"
            to={"/"}
            onClick={goBack}
            activeClassName="animated"
          >
            <LogoInline className="logo" />
          </Link>
        </h1>
      </div>
      <div>
        <SocialIcons socials={socials} />
        <Link
          to="/contact-me"
          id="contact-btn"
          className="btn blue outlined"
          replace={isContact ? true : false}
        >
          Contact Me
          {/* {isContact ? <img src={closeIcon} alt="close" /> : "Contact Me"} */}
        </Link>
      </div>
    </header>
  );
};

export default Header;
