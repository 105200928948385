/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import bgImagePreload from "../images/bg-tiled-mt_324x486_min.png";

interface Props {
  description?: string;
  lang?: string;
  meta?: any;
  title: string;
}

function SEO({
  description = "",
  lang = "en",
  meta = [],
  title,
}: Props): JSX.Element {
  const { site, wordpressWpSettings } = useStaticQuery(
    graphql`
      query SiteSeoQuery {
        site {
          siteMetadata {
            title
            description
            author
            externalCSS
          }
        }
        wordpressWpSettings {
          title
          description
        }
      }
    `
  );

  const metaDescription =
    description ||
    wordpressWpSettings.description ||
    site.siteMetadata.description;
  const metaTitle = wordpressWpSettings.title || site.siteMetadata.title;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="preload" href={bgImagePreload} as="image" />
      {site.siteMetadata.externalCSS.map((link: string) => (
        <link key={link} href={link} rel="stylesheet" />
      ))}
    </Helmet>
  );
}

export default SEO;
