// import { Link } from "gatsby";npm
import React from "react";
import LogoInline from "../images/svg/deswolf-logo.svg";

// interface Props {}

const Footer = (): JSX.Element => (
  <footer id="footer">
    <div>
      <p>&copy; {new Date().getFullYear()}</p>
    </div>
    <div>
      <div
        data-tip="Back to top"
        data-tip-position="top"
        className="logo-holder"
      >
        <a href="#header" className="svg-holder">
          <LogoInline className="logo" />
        </a>
      </div>
    </div>
    <div>
      {/* <p>
        <span>
          Made <span>mostly</span> by my own tender hands.
        </span>
        <br />
        <span>No animals were harmed in the making of this website.</span>
      </p> */}
      <p>
        Made with a{" "}
        <span
          style={{
            verticalAlign: "middle",
            fontSize: "1.8rem",
            marginLeft: "3px",
          }}
        >
          ⌨️
        </span>{" "}
        using Wordpress, Gatsby &amp; React
      </p>
    </div>
  </footer>
);

export default Footer;
